import React, { useState, useEffect, useMemo } from 'react';
import { FaBell } from 'react-icons/fa';
import { IoReorderThreeOutline } from "react-icons/io5";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './Header.css';
import CookiesServices from '../../services/CookiesService';
import { FiSearch } from "react-icons/fi";
import SearchMenu from '../search-menu/SearchMenu';
import UserImg from '../../assets/user.jpg';
import '../../colors/colors.css'

const Header = ({ onToggleSidebar, currentPath }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [branch, setBranch] = useState('');
  const cookiesServices = useMemo(() => new CookiesServices(), []);
  const [openSearchMenu, setOpenSearchMenu] = useState(false);

  useEffect(() => {
    const storedCode =cookiesServices.get('code');
    const storedName = cookiesServices.get('name');
    const storedBranch = cookiesServices.get('branch');
    if (storedCode && storedName && storedBranch) {
      setCode(storedCode);
      setName(storedName);
      setBranch(storedBranch);
    }
  }, [cookiesServices]);

  const handleUserIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = () => {
    cookiesServices.remove('AccessToken');
    cookiesServices.remove('UserName');
    cookiesServices.remove('Level');
    cookiesServices.remove('code');
    cookiesServices.remove('session_state')
    cookiesServices.remove('name');
    cookiesServices.remove('branch');
    window.location.reload();
  };

  const handleSearchMenu = () => {
    setOpenSearchMenu(!openSearchMenu);
  };

  const closeSearchMenu = () => {
    setOpenSearchMenu(false);
  };

  const formatPath = (path) => {
    if (!path) return '';

    const pathParts = path.replace(/^\/+/, '').split('/');

    return pathParts
      .map((part) => {
        return part.replace(/([a-z])([A-Z])/g, '$1 $2')
                   .replace(/^./, str => str.toUpperCase());
      })
      .join(' ');
  };

  return (
    <div className="header">
      <div className="toggle-section" >
        <IoReorderThreeOutline className="toggle" onClick={onToggleSidebar} />
        <div className="header-path">
        {formatPath(currentPath)}
      </div>
      </div>

      <div className="header-actions">
        <button className="header-button" onClick={handleSearchMenu}>
          <FiSearch className="icon" />
        </button>
        <FaBell className="icon" />
        <div className="user-icon" onClick={handleUserIconClick}>
          <img src={UserImg} alt="User" className="user-image" />
          <div className="user-info">
          <span className="code">{code || 'code'}</span>
          <span className="level">{branch || 'branch'}</span>
          <span className="username">{name || 'name'}</span>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      {openSearchMenu && <SearchMenu onClose={closeSearchMenu} />}
    </div>
  );
};

export default Header;

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTachometerAlt, FaBriefcase, FaClipboardList, FaNewspaper, FaBirthdayCake, FaChartPie, FaUserFriends, FaFileContract, FaCog } from 'react-icons/fa';
import './SideBar.css';
import EHubImage from '../../../src/assets/logo1.png';
import EHubImage2 from '../../../src/assets/logo2.png';
import '../../colors/colors.css';
import VersionContainer from '../version-container/VersionContainer';

const menuItems = [
  // { id: 'dashboard', label: 'Dashboard', icon: <FaTachometerAlt />, path: '/dashboard' },
  { id: 'dash', label: 'Dashboard', icon: <FaTachometerAlt />, path: '/dashboard' },
  { id: 'newBusiness', label: 'New Business', icon: <FaBriefcase />, path: '/newBusiness' },
  { id: 'renewals', label: 'Renewals', icon: <FaFileContract />, path: '/renewals' },
  { id: 'policyDetails', label: 'Policy Details', icon: <FaClipboardList />, path: '/policyDetails' },
  { id: 'salesPlan', label: 'Sales Plan', icon: <FaChartPie />, path: '/salesPlan' },
  { id: 'news', label: 'News', icon: <FaNewspaper />, path: '/news' },
  { id: 'birthdays', label: 'Birthdays', icon: <FaBirthdayCake />, path: '/birthdays' },
  { id: 'insights', label: 'Insights', icon: <FaCog />, path: '/insights' },
  { id: 'advisorSummary', label: 'Advisor Summary', icon: <FaUserFriends />, path: '/advisorSummary' },
];

const Sidebar= () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [clickedPath, setClickedPath] = useState('');

  const handleClick = (path) => {
    setClickedPath(path);
  };

  return (
    <div className="sidebar">
  <div className="sidebar-content">
    <div className="eHub-image">
      <img src={EHubImage2} alt="EHub" className="eHub-image" />
    </div>
    <ul className="sidebar-list">
      {menuItems.map(item => (
        <li key={item.id}>
          <Link
            to={item.path}
            className={`sidebar-button ${currentPath === item.path ? 'selected' : ''} ${clickedPath === item.path ? 'clicked' : ''}`}
            onClick={() => handleClick(item.path)}
          >
            {item.icon}
            <span className={`sidebar-label ${currentPath === item.path ? 'selected' : ''}`}>
              {item.label}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  </div>
  <div className="version-main-wrapper">
    <VersionContainer />
  </div>
</div>

  );
};

export default Sidebar;

import React from 'react';
import './search-bar.css';
import { FiSearch } from 'react-icons/fi'; 

export default function SearchBar() {
  return (
    <div className="search-bar-container">
      <input
        type="text"
        className="search-input"
        placeholder="Search for..."
      />
      <div className="search-icon">
        <FiSearch size={20} /> 
      </div>
    </div>
  );
}

import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppRoutes from './Routes';
import './App.css';
import CookiesServices from './services/CookiesService';
import Sidebar from './layout/sidebar/SideBar';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import CircularProgress from '@mui/material/CircularProgress';

const App = () => {
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookieService = useMemo(() => new CookiesServices(), []);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const authenticateUser = async () => {
            // Attempt to save login details from URL
            const loginSuccess = await cookieService.saveLoginDetailsFromUrl();

            if (loginSuccess) {
                setIsLoggedIn(true);
                // navigate('/dashboard');
            } else {
                // Redirect to WSO2 for authentication if cookies are not valid
                window.location.href = 'https://authuat.lolc.lk/oauth2/authorize?response_type=code&client_id=ndaT1lrjRBuFIrUFIQhc_ZoW3uwa&redirect_uri=https://ehubuat.lolclife.com/&scope=openid&client_secre=6FuzVcQbImuGg4mGfeijryfTxlsa&code_challenge=IMbNq8j9HZBlbLuZ4nHcYOv1ZkRF5TVNAfVIGyeUsi0&code_challenge_method=S256';
            }
        };

        authenticateUser();
    }, [cookieService, navigate]);

    return (
        <div className="app">
            {!isLoggedIn ? (
                <div className="loading-overlay">
                <CircularProgress
                    size={60}
                    thickness={4.5}
                    sx={{
                        color: 'linear-gradient(to right, red, blue)',
                    }}
                />
            </div>
            ) : (
                <>
                    {isSidebarVisible && <Sidebar />}
                    <div className={`main-content ${isSidebarVisible ? 'with-sidebar' : 'full-width'}`}>
                        <Header onToggleSidebar={() => setSidebarVisible(!isSidebarVisible)} currentPath={location.pathname}  />
                        <AppRoutes isLoggedIn={isLoggedIn} />
                        <Footer />
                    </div>
                </>
            )}
        </div>
    );
};

export default App;

import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress'; 


const DashboardMain = lazy(() => import('./pages/Dash/Dash'));
const NewBusiness = lazy(() => import('./pages/NewBusiness/NewBusiness'));
const Renewals = lazy(() => import('./pages/Renewals/Renewals'));
const PolicyDetails = lazy(() => import('./pages/PolicyDetails/PolicyDetails'));
const News = lazy(() => import('./pages/News/News'));
const Birthdays = lazy(() => import('./pages/Birthdays/Birthdays'));
const Insights = lazy(() => import('./pages/Insights/Insights'));
const AdvisorSummary = lazy(() => import('./pages/AdvisorSummary/AdvisorSummary'));
const SalesPlan = lazy(() => import('./pages/SalesPlan/SalesPlan'));

const ProtectedRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate to="/" replace />;
};

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const AppRoutes = ({ isLoggedIn }) => {
  return (
    <Suspense
      fallback={
        <div className="loading-overlay">
          <CircularProgress color="secondary" />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <DashboardMain />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newBusiness"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <NewBusiness />
            </ProtectedRoute>
          }
        />
        <Route
          path="/renewals"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Renewals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/policyDetails"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <PolicyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesPlan"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <SalesPlan />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <News />
            </ProtectedRoute>
          }
        />
        <Route
          path="/birthdays"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Birthdays />
            </ProtectedRoute>
          }
        />
        <Route
          path="/insights"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Insights />
            </ProtectedRoute>
          }
        />
        <Route
          path="/advisorSummary"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AdvisorSummary />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

AppRoutes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRoutes;

import React, { useState } from 'react';
import './role.css';

const Role = ({ roles, title, onRoleSelect }) => {
  const colors = ['#ffcccc', '#b3e3ff'];

  const handleRoleClick = (role) => {
    onRoleSelect(role); // pass the selected role back to the parent
  };

  return (
    <div className="role-container">
      <p className="role-title">{title}</p>
      <div className="roles">
        {roles.map((role, index) => (
          <div
            key={index}
            className="role-item"
            onClick={() => handleRoleClick(role)} // handle click
          >
            <div
              className="role-icon"
              style={{ backgroundColor: colors[index % colors.length] }}
            ></div>
            <p className="role-name">{role.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Role;

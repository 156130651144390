import React, { useState, useEffect } from 'react';
import './search-menu.css';
import Role from '../../Components/role-layer/Role';
import SearchBar from '../../Components/search-bar/SearchBar';
import DatePicker from '../../Components/date-picker/DatePicker';
import CookiesServices from '../../services/CookiesService';
import axios from 'axios';

const SearchMenu = ({ onClose }) => {
  const [selectedRole, setSelectedRole] = useState(null); // For "Select for Role"
  const [selectedByRole, setSelectedByRole] = useState(null); // For "Select by Role"
  const [locationSearchTerm, setLocationSearchTerm] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const cookiesServices = new CookiesServices();

  // Fetch locations data from API (used for dropdown)
  const fetchLocations = async () => {
    setIsLoading(true);
    const accessToken = cookiesServices.get('AccessToken');
    const UserName = cookiesServices.get('UserName');
    const level = cookiesServices.get('Level');

    try {
      const response = await fetch('http://localhost:9000/api/hierarchy', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'AuthenticationToken': accessToken,
          'UserName': UserName,
          'Level': level,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch locations');
      const data = await response.json();
      setLocations(data || []);
      setFilteredLocations(data || []);
    } catch (error) {
      setFeedback({ message: error.message, type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch additional data using axios with dynamic id
  const fetchAdditionalData = async (id) => {
    const accessToken = cookiesServices.get('AccessToken');
    const UserName = cookiesServices.get('UserName');
    const level = cookiesServices.get('Level');

    try {
      const response = await axios.get(`http://localhost:9000/api/hierarchy/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'AuthenticationToken': accessToken,
          'UserName': UserName,
          'Level': level,
        },
      });

      if (response.status === 200) {
        // Process the response data as needed
        console.log('Additional Data:', response.data);
      } else {
        throw new Error('Failed to fetch additional data');
      }
    } catch (error) {
      setFeedback({ message: error.message, type: 'error' });
    }
  };

  useEffect(() => {
    fetchLocations(); // Initial load of locations
  }, []);

  // Filter locations based on search term
  const filtered = filteredLocations.filter(location =>
    location.name.toLowerCase().includes(locationSearchTerm.toLowerCase())
  );

  // Handle role selection for "Select for role"
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    // Do not change cookies here for "Level" and "SelectedLocation"
    const rolePrefix = role.id.split('_')[0];
    const filtered = locations.filter(location =>
      location.parentId === role.id || location.childId.startsWith(rolePrefix)
    );
    setFilteredLocations(filtered);  

    fetchAdditionalData(role.id); // Fetch additional data based on the selected role's id
  };

  // Handle role selection for "Select by Role"
  const handleByRoleSelect = (role) => {
    setSelectedByRole(role);
    // Do not change cookies here for "Level" and "SelectedLocation"
    const rolePrefix = role.id.split('_')[0];
    const filtered = locations.filter(location =>
      location.parentId === role.id || location.childId.startsWith(rolePrefix)
    );
    setFilteredLocations(filtered);

    fetchAdditionalData(role.id); // Fetch additional data based on the selected role's id
  };

  // Handle location selection
  const handleLocationSelect = (id, name) => {
    cookiesServices.set('Level', id); // Update Level only when location is selected
    cookiesServices.set('SelectedLocation', name);
    setLocationSearchTerm(name);
    //window.location.reload(); // Refresh page
  };

  return (
    <div className="search-menu">
      <div className="search-menu-header">
        Sort Options
        <div className="close-button" onClick={onClose}>X</div>
      </div>

      <div className="content">
        {/* Select Role Section */}
        <div className="search-menu-content">
          <Role
            title="Select for Role"
            roles={[
              { name: 'National', id: 'N_1' },
              { name: 'Province', id: 'P_1' },
              { name: 'Zonal', id: 'Z_1' },
              { name: 'Branch', id: 'B_1' },
              { name: 'Sales Manager' },
              { name: 'Unit Leader' },
            ]}
            onRoleSelect={handleRoleSelect}
          />
        </div>

        {/* Locations Section */}
        {(selectedRole?.name || selectedByRole?.name) && (
          <div className="locations-container">
            <p className="locations-title">{`Select ${
              selectedRole?.name || selectedByRole?.name
            } Location`}</p>

            <div className="location-search-container">
              <input
                type="text"
                placeholder="Search Locations..."
                value={locationSearchTerm}
                onChange={(e) => setLocationSearchTerm(e.target.value)}
                className="location-search-input"
              />

              {locationSearchTerm && (
                <div className="dropdown-container">
                  {filtered.length > 0 ? (
                    filtered.map((location, index) => (
                      <div
                        key={index} 
                        className="location-item"
                        onClick={() => handleLocationSelect(location.childId, location.name)} // Call handleLocationSelect on click
                      >
                        <p>{location.name}</p>
                      </div>
                    ))
                  ) : (
                    <div className="no-results"><p>No locations found</p></div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="search-menu-content">
          <Role
            title="Select by Role"
            roles={[
              { name: 'National', id: 'N_1' },
              { name: 'Province', id: 'P_1' },
              { name: 'Zonal', id: 'Z_1' },
              { name: 'Branch', id: 'B_1' },
              { name: 'Sales Manager' },
              { name: 'Unit Leader' },
            ]}
            onRoleSelect={handleByRoleSelect}
          />
        </div>

        {/* Other components like SearchBar and DatePicker */}
        <SearchBar />
        <DatePicker />

        {feedback && <div className={`feedback ${feedback.type}`}>{feedback.message}</div>}
      </div>
    </div>
  );
};

export default SearchMenu;

import React, { useState } from 'react';
import './date-picker.css';

export default function DatePicker() {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  return (
    <div className="date-picker">
      <div className="date-picker-field">
        <label htmlFor="from-date">From:</label>
        <input
          type="date"
          id="from-date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
      </div>

      <div className="date-picker-field">
        <label htmlFor="to-date">To:</label>
        <input
          type="date"
          id="to-date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
      </div>
    </div>
  );
}
